





















































































import Vue from "vue";
import assert from "assert";

import GameManager from "#root/game/game";

import * as PointerPNG from "#root/game/data/pointer.png";

import * as ControlsPNG from "#root/game/data/controls/wasd.png";
import * as MousePNG from "#root/game/data/controls/mouse.png";
import * as MouseLeftPNG from "#root/game/data/controls/mouseLeft.png";
import * as MouseRightPNG from "#root/game/data/controls/mouseRight.png";
import * as EPNG from "#root/game/data/controls/e.png";
import * as EscPNG from "#root/game/data/controls/esc.png";
import * as SpacePNG from "#root/game/data/controls/space.png";

import MyInputManager from "#root/game/inputManager";
const PauseMenu = () => import("#root/components/game/PauseMenu.vue");
const BlocksMenu = () => import("#root/components/game/BlocksMenu.vue");

import { WorldInfo } from "#mws/interfaces";
import { LocaleKeys } from "#mws/data";
import SessionModule from "#root/store/session";
import AppModule from "#root/store/app";
import { t } from "#root/lib/utils";

interface Tutorial {
  image: any,
  textKey: string
}

export default Vue.extend({
  name: "Game",
  components: { PauseMenu, BlocksMenu },
  data: () => ({
    tutorials: [{
      image: EscPNG,
      textKey: LocaleKeys.game.controls.menu
    },
    {
      image: ControlsPNG,
      textKey: LocaleKeys.game.controls.move
    },
    {
      image: SpacePNG,
      textKey: LocaleKeys.game.controls.jump
    },
    {
      image: MousePNG,
      textKey: LocaleKeys.game.controls.look
    },
    {
      image: MouseLeftPNG,
      textKey: LocaleKeys.game.controls.place
    },
    {
      image: MouseRightPNG,
      textKey: LocaleKeys.game.controls.break
    },
    {
      image: EPNG,
      textKey: LocaleKeys.game.controls.blockMenu
    }] as Tutorial[],
    pointerSrc: PointerPNG,
    selectedMenu: undefined as any,
    blocksMenu: undefined as any,
  }),
  computed: {
    worldInfo(): WorldInfo {
      const joinedWorld = SessionModule.joinedWorld;
      assert(joinedWorld !== undefined);
      return joinedWorld.info;
    },
  },
  watch: {
    menuShown: function (value) {
      if (value === false) {
        MyInputManager.instance.enterPointerLock();
      }
    },
  },
  methods: {
    openMenu(): void {
      if (this.selectedMenu !== undefined) return;
      this.selectedMenu = this.$refs.pauseMenu;
      this.selectedMenu.open();
    },
    openBlocks(): void {
      if (this.selectedMenu === this.$refs.blocksMenu) {
        this.selectedMenu.close();
        return;
      } else if (this.selectedMenu !== undefined) {
        return;
      }
      this.selectedMenu = this.$refs.blocksMenu;
      this.selectedMenu.open();
      MyInputManager.instance.exitPointerLock();
    },
    onMenuClosed(): void {
      MyInputManager.instance.enterPointerLock();
      this.selectedMenu = undefined;
    },
    onReturnToMenu(): void {
      AppModule.loadingAlert();
      this.selectedMenu = undefined;
      GameManager.dispose();
      this.$router.push("/menu");
    },
    t: t,
  },
  mounted() {
    this.$nextTick().then(() => {
      let container = document.getElementById("gameCanvas");
      if (container) {
        const joinedWorld = SessionModule.joinedWorld;
        assert(joinedWorld !== undefined);

        GameManager.load(container as HTMLCanvasElement, joinedWorld);
        GameManager.uiEvent.get("openMenu").subscribe(this.openMenu);
        GameManager.uiEvent.get("openBlocks").subscribe(this.openBlocks);
      } else {
        console.error("Game canvas not found");
      }
    });
  },
  beforeDestroy() {
    GameManager.uiEvent.remove("openMenu");
    GameManager.uiEvent.remove("openBlocks");
  },
});
